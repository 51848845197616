<template>
<span>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <g fill-rule="evenodd">
    <path d="m284.41 49.246c-15.791 3.9087-31.978 11.682-43.365 19.208-11.387 7.5263-41.13 26.491-47.921 32.5-6.5839 5.8265-14.361 7.8589-17.764 15.038-1.3124 2.7688 8.8996 11.121 10.901 11.988 5.1932 2.2504 13.658-1.7399 17.521-1.9782 12.536-0.7734 25.382-2.2736 38.079 5.4546-2.3493 2.133 1.3813-0.16339-3.421 18.544-1.6935 6.5971-5.3941 23.712-0.84695 26.978 4.5178 3.2444 7.2771-9.4032 11.766-7.4668-0.002258 0.003006-9.46e-4 0.010651-0.002563 0.013367-9.77e-4 0.002411-0.002182 0.0112-0.002533 0.013351 2.59e-4 0.001831-0.003448 0.01178-0.002563 0.013336 0.001511 0.001297 0.011856 0.001434 0.013977 0.002441 0.013657 0.003677 0.04364 0.001846 0.072357-0.00116 0.008453 0.003662 0.019485 0.001205 0.027954 0.004883 0.031723 0.013702 0.060623 0.029877 0.092651 0.043747l0.0267-0.0643c2.125-0.49391 18.692-7.0582 33.817-4.4186 8.5257 1.4879 22.124-2.6834 29.947-4.8594 12.406-3.4508 29.822-6.5693 40.87-6.7473 8.1389-0.13116 15.503-4.2751 23.333-4.7582 4.6195-0.28502 0.33615-13.679 0.24066-15.094-0.45889-6.8001-9.8708-17.249-14.718-26.535-4.8474-9.2857-12.971-21.073-21.919-30.316-8.9473-9.2432-21.018-21.212-29.517-24.895-8.4989-3.6829-10.089-6.9114-27.228-2.6693z" fill="#d00000" stroke="#000" stroke-width=".833"/>
    <path d="m246.67 81.874c-10.246 2.2321-19.021 31.684-31.649 36.804-8.2593 3.3486-30.767 8.4363-28.766 9.3035 5.1932 2.2504 13.658-1.7399 17.521-1.9782 12.536-0.7734 25.382-2.2736 38.079 5.4546-2.3493 2.133 1.3813-0.16339-3.421 18.544-1.6935 6.5971-5.3941 23.712-0.84695 26.978 4.5178 3.2444 7.2771-9.4032 11.766-7.4668-0.002258 0.003006-9.46e-4 0.010651-0.002563 0.013367-9.77e-4 0.002411-0.002182 0.0112-0.002533 0.013351 2.59e-4 0.001831-0.003448 0.01178-0.002563 0.013336 0.001511 0.001297 0.011856 0.001434 0.013977 0.002441 0.013657 0.003677 0.04364 0.001846 0.072357-0.00116 0.008453 0.003662 0.019485 0.001205 0.027954 0.004883 0.031723 0.013702 0.060623 0.029877 0.092651 0.043747l0.0267-0.0643c2.125-0.49391 18.692-7.0582 33.817-4.4186 8.5257 1.4879 22.124-2.6834 29.947-4.8594 12.406-3.4508 29.822-6.5693 40.87-6.7473 8.1389-0.13116 15.503-4.2751 23.333-4.7582 4.6195-0.28502-28.708 2.2847-15.426-6.3557 5.8923-3.833-58.997 7.989-83.909-17.576-24.668-25.314-21.048-45.235-31.541-42.95z" fill="#0e0000" opacity=".101"/>
    <g stroke="#000">
    <path d="m176.41 112.85c1.9954 1.1891 15.397 16.434 16.98 15.367 0.19139-1.6834-1.3716-2.3461-2.471-2.5383 1.4721-1.6826 1.7496-3.442-0.80502-3.5932-2.8324-0.44978-5.9245-0.76533-2.8842-3.5054 1.4037-1.5357-0.094391-4.263-0.9563-5.6502-2.2644-1.0999-3.9708-3.0901-4.899-4.443" opacity=".27" stroke-width=".83"/>
    <g stroke-width=".601">
      <path d="m358.3 145.68c-4.1873-1.3355-9.4416-2.8781-13.144-2.6722-2.5428-1.9318-4.4947 0.44873-6.6979 1.0823-0.36975-3.7195-6.4686 1.5729-9.1551 1.6033-1.9076 0.98468-0.59274-2.9315-4.0088-1.4419-4.247 0.36084-6.8742 0.62326-10.025 1.85-0.75678 4.0496-7.5579 6.3308-8.5758 1.8923-3.7081 1.4512-10.604 4.2661-10.648-0.32428-5.9367 4.1411-6.1256 7.8833-11.921 9.8819 0.51288-3.5843-12.571-5.5128-14.355-1.751-3.5907 1.3004 0.74692-4.7107-4.442-3.0588-5.8447-1.5282-2.4429 9.064-14.36 4.7335-6.6354-0.19196-2.9608 8.4498-7.3399 12.07-1.1667 3.8068-6.2286-1.3262-8.2773-1.0412 0.47653 6.0276 1.915 3.9836 0.40419 8.0845-0.90146 5.1375-2.1081 7.71 4.1764 8.7798 2.035-2.4782-0.10231-1.4297 0.35288 3.1423 3.6289-0.95001 8.3993-6.3881 7.9096-0.79924 2.4625-3.3726 10.258-5.1304 10.16-8.763 3.946 0.37251 8.3745 4.336 11.706-0.48163 3.3441-3.5586 8.1509-5.209 8.2014-2.4605 4.0651 3.4147 4.4717 2.7227 8.698 1.8537-1.0886-4.4028 9.8902-0.30252 4.0926-4.6858 2.3027-2.0738 8.5348-3.2857 12.136-2.5651 3.186 4.1716 12.894 4.5168 14.404-0.88294 0.31696-4.2474 7.6889-5.7002 10.445-2.7636 4.7933-3.7796 12.354 1.7125 16.479-1.1847-3.7076-4.3276 5.24-7.1554 6.9109-3.8374 3.2828-1.5852 6.3378-7.2892 9.9289-3.2056 4.1304-1.2105 9.1738-2.8937 12.668-4.1552 2.6225 3.888 9.4114-0.11174 6.4685-4.8175-1.593-3.3612 5.2317 1.6549 3.3737-2.6097-2.1135-4.5874-5.0816-10.963-8.7181-10.752-4.9522-1.1128-7.8886 1.8926-8.0942 6.1458-0.062622-3.1037-5.9131-0.078339-8.9819 1.0471l-1.6824 0.36-2.0101-1.2751" opacity=".294"/>
      <path d="m361.07 146.81c-4.1873-1.3355-8.0676-1.5233-11.77-1.3174-2.5428-1.9318-5.5606-1.2983-7.7638-0.6647-0.36975-3.7195-5.4027 3.32-8.0892 3.3504-1.9076 0.98466-0.59274-2.9315-4.0088-1.4419-4.247 0.36084-9.1706-2.0075-12.321-0.78082-0.7568 4.0496-7.5579 6.3308-8.5758 1.8923-3.7081 1.4512-8.307 6.8969-8.3517 2.3065-6.9596-0.27437-9.9411 5.8794-15.737 7.878 0.51285-3.5843-8.7559-3.5089-10.539 0.25288-3.5907 1.3004-0.95486-6.7918-6.1438-5.1398-5.8447-1.5282-5.6852 8.9441-12.658 6.8145-6.6354-0.19196-5.7087 5.7402-10.088 9.3603-1.1667 3.8068-3.4806 1.3834-5.5294 1.6684 3.3713 4.3028-10.336 3.4065-6.2667 10.042-0.90147 5.1375 0.47682 8.8301 6.7613 9.9 2.035-2.4782 3.9837-4.5076 4.4389 0.064346 3.6289-0.95001 8.3992-6.3881 7.9096-0.79924 2.4625-3.3726 10.258-5.1304 10.16-8.763 3.946 0.3725 8.3746 4.3359 11.706-0.48164 3.3441-3.5586 3.8964-0.655 3.9468 2.0935 4.0651 3.4147 8.7262-1.8313 12.953-2.7003-1.0886-4.4028 9.8901-0.30252 4.0926-4.6858 2.3027-2.0738 8.5348-3.2857 12.136-2.5651 3.16 2.6958 12.894 4.5168 14.404-0.88294 0.31696-4.2474 7.6889-5.7002 10.445-2.7636 4.7933-3.7796 12.354 1.7125 16.479-1.1847-3.7076-4.3276 5.24-7.1554 6.911-3.8374 3.2828-1.5852 6.3378-7.2892 9.9289-3.2056 4.1303-1.2105 9.1738-2.8937 12.668-4.1552 2.6225 3.888 9.4114-0.11174 6.4685-4.8175-1.5931-3.3612 5.2317 1.6549 3.3737-2.6097 0.041504-4.8803-2.0023-12.432-8.7181-10.752-4.9522-1.1128-7.8886 1.8926-8.0942 6.1458-0.062622-3.1037-7.8818-1.9829-10.951-0.85744l-1.0053 0.4781-0.7185 0.5113" fill="#fff"/>
    </g>
    <path d="m240.33 141.28c1.7646-20.644 13.366-30.046 11.324-46.557-1.2071-9.7593-10.979-2.3616-7.794 6.7933" fill="none" stroke-width=".833"/>
    </g>
    <path d="m245 169.45c-1.1667 3.8068-3.4806 1.3834-5.5294 1.6684 3.3713 4.3028-10.336 3.4066-6.2667 10.042-0.90146 5.1375 0.47684 8.8301 6.7613 9.9 2.035-2.4782 3.9837-4.5076 4.4389 0.064362 3.6289-0.95003 8.3993-6.3881 7.9096-0.79926 2.4625-3.3726 10.258-5.1304 10.16-8.763 3.946 0.3725 8.1854 3.7145 11.516-1.1032 3.3441-3.5586 4.0854-0.033478 4.1359 2.715 4.0651 3.4147 8.7262-1.8313 12.953-2.7003-1.0886-4.4028 4.9826-0.78096 4.0926-4.6858-0.58289-2.5574 18.907-1.8002 16.104-4.3011-8.3765-7.4731-21.462 3.7864-23.355 3.0678-1.8932-0.71869-3.985-0.28281-1.3402-1.3743s-3.789 0.70375-9.1472-1.0079c-5.3583-1.7117-9.2804 0.45229-9.6383 4.7218-4.0359-2.2574-4.325 0.30669-10.613 1.8791 4.0065-5.2676-7.0805-1.3994-7.0805-1.3994s1.9316-5.7756-1.5394-5.0133c1.8661-3.0142-0.20508-3.9429-0.20508-3.9429s1.0222-2.5884-3.3568 1.0317z" opacity=".112" stroke-width=".722"/>
    <path d="m179.23 108.61c-0.73148-1.1716-3.0107-1.4942-3.304-2.4878 0.50942-1.154-1.4943-0.31697-1.8891-0.95136-2.3571 1.7353-5.6109 2.4865-7.3576 4.8732 0.9288-0.18318 0.92659-1.4043-0.088257-0.50344-1.9113 0.85394-3.7585 2.354-3.0398 4.4944 0.17407 1.0983-1.3631 2.0435 0.51338 2.3036-0.24469 1.7584-3.1779 1.6844-3.1796 3.2364 0.64926 1.6928 0.36366 3.4569-1.1805 4.6443-1.5274 1.2846-0.89838 3.1754-0.8427 4.7359-0.76819 1.0438-1.2006 2.0143-0.18811 3.0086 0.21437 1.7932 1.8696 2.9806 1.882 4.8463 0.69685 0.97789 2.0942 0.91737 2.808 2.0224 2.0851 0.15941 2.2381 2.7779 4.2244 3.141 1.7062 0.50047 3.725 0.15346 5.2624 0.52046 1.2609 0.91931 2.7082 1.5601 4.2956 1.8182 1.9954 1.1422 2.6737-1.7005 3.8813-2.6159 0.062622-1.4312 1.206-1.5426 1.9717-0.33745 1.7426 1.0625 3.5482-0.76724 4.7976-1.7354 1.2214-1.1973 2.5917-2.2338 3.9518-3.2836 1.9309-1.9602 2.6164-4.7657 2.5084-7.3459 0.19139-1.6169-1.4803-1.7064-2.5797-1.891 0.4971-1.3556 1.7496-3.3061-0.80502-3.4512-1.465-0.34412-4.7864-1.8162-2.8842-3.3669 1.4037-1.4751-0.52811-3.4608-1.39-4.7932-1.4927-1.0684-2.36-2.5567-3.2881-3.8562-1.797-0.25751-3.481-1.2396-3.9391-2.954-0.47971-1.3615-0.13284-0.35329 0.018524 0.30952" fill="#fff" stroke="#000" stroke-width=".814"/>
    <path d="m164.32 126.6c-0.89986-0.9948 2.6533-0.52619 2.36-1.5198 0.50942-1.154-0.10017-1.2021-0.495-1.8364-2.3571 1.7353-1.802-2.3758-3.5487 0.011009 0.92879-0.18319 0.77608-3.2009-0.23877-2.3001-1.9113 0.85394-1.5064-2.9342-1.5081-1.3823 0.64926 1.6928 0.36366 3.4569-1.1805 4.6443-1.5274 1.2846-0.89838 3.1754-0.8427 4.7359-0.76819 1.0438-1.2006 2.0143-0.18811 3.0086 0.21437 1.7932 1.8696 2.9806 1.882 4.8463 0.69685 0.97789 2.0942 0.91737 2.808 2.0224 2.0851 0.15941 2.2381 2.7779 4.2244 3.141 1.7062 0.50047 3.725 0.15346 5.2624 0.52046 1.2609 0.91931 2.7082 1.5601 4.2956 1.8182 1.9954 1.1422 2.6737-1.7005 3.8813-2.6159 0.062622-1.4312 1.206-1.5426 1.9717-0.33745 1.7426 1.0625 3.5482-0.76724 4.7976-1.7354 1.2214-1.1973-4.6901 0.1816-3.33-0.86818 1.9308-1.9602-4.3566 2.7361-4.4646 0.1559 0.19139-1.6169-6.9517 2.0572-5.4389-0.78534 0.4971-1.3556 0.86311-0.37798-1.6915-0.52316-1.465-0.34412-5.5092-2.7764-3.6071-4.3272 1.4037-1.4751-0.7542 0.23065-1.6161-1.1018-1.4927-1.0684-0.53928-0.48012-1.4674-1.7796-1.797-0.25751-1.0069-0.91809-1.465-2.6325-0.7774 2.0115-0.55158-1.822-0.40021-1.1592z" opacity=".096" stroke-width=".996"/>
    <path d="m241.45 131.92s7.3867-16.936 9.1616-23.694c1.6606-6.3219 1.4457-15.972-1.0414-17.758-2.4871-1.7861-5.7584 1.303-6.1032 4.9609-0.34479 3.6579-0.63356 4.5797 1.036 11.557 0.75685 3.163 0.37886 10.66-0.65015 14.199-1.029 3.5385-3.2744 11.597-2.4028 10.735z" fill-opacity=".223"/>
  </g>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'santa',

  mixins: [ThemeMixin]
}
</script>
